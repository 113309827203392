import React from 'react';
import RouterIndex from './router/index'

function App() {
  return (
    <div className="App">
      <RouterIndex />
    </div>
  );
}

export default App;