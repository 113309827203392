import MyServer from './MyServer';

class MyApi extends MyServer {

  async queryAllApi(method,url,tableName) {
    try {
      let result = await this.axios(method,url,tableName);
      return result
    } catch (err) {
      throw err
    }
  }
  async queryByIdApi(method,url,tableName,id) {
    try {
      let result = await this.axiosById(method,url,tableName,id);
      return result
    } catch (err) {
      throw err
    }
  }

}
export default new MyApi();
















// 原来的异步,结果没到就return
// import envconfig from '@/envconfig/envconfig';

// class MyApi {

//   queryAllApi(meth, url, tableName) {

//     let data = new FormData()
//     data.append('tableName', tableName);

//     fetch(envconfig.javaURL + url, { method: meth, body: data })
//       .then(response => {
//         response.json().then((data) => {
//           console.log(data)
//           return data
//         })
//       })
//       .catch(error => {
//         console.log(error)
//         return 'false'
//       })
//   }
// }

// export default new MyApi()