import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MyApi from '../../api/MyApi'

class CasesMain extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: false,
      message: "",
      casesData: []
    }
  }
  initData(){

    let res = {"c_relation":[{"path":"http://www.shuolei-it.com/","imagePath":"res/static/img/cases1.png","id":"1","title":"無錫碩磊科技有限公司","contentMD":"### &emsp;&emsp;無錫碩磊科技有限公司（無錫）は専門的にコンピュータソフトウェア分野の技術開発、技術コンサルティング、技術サービス、成果譲渡、コンピュータソフトハードウェアの開発と販売、ソフトウェア技術のアウトソーシングとサービスに従事しています。日本の東京SUN FLOWER SOFT株式会社に属しています。\r\n### &emsp;&emsp;SUN FLOWER SOFT株式会社（東京）は東京で数年の発展の歴史を持ち、すでに自分の比較的成熟したプロジェクト経験、資源とチームを持っています。サービス分野は製造、医療、電力、公共、証券、保険、金融などの各業種に及んでいます。\r\n### &emsp;&emsp;無錫碩磊科技有限公司は創立以来ずっと「人間本位」の募集基準を持っています。IT業界に熱中している限り、ソフトウェア開発経験や日本語の基礎があり、品行が整っています。","content":"無錫碩磊科技有限公司（無錫）は専門的にコンピュータソフトウェア分野の技術開発、技術コンサルティング、技術サービス、成果譲渡、コンピュータソフトハードウェアの開発と販売、ソフトウェア技術のアウトソーシングとサービスに従事しています。日本の東京SUN FLOWER SOFT株式会社に属しています。"}],"message":"请求数据成功","status":true}
    res.casesData = res.c_relation
    delete res.c_relation
    this.setState(res)
    // MyApi.queryAllApi('post','queryAllApi','c_relation').then((res) => {
    //   res.casesData = res.c_relation
    //   delete res.c_relation
    //   this.setState(res)
    // })
  }

  componentDidMount() {
    this.initData()
  }

  render() {
    let style = ["", "layui-inline content", "layui-inline content even center", "layui-inline content", "layui-inline content even", "layui-inline content center", "layui-inline content even"]
    let caselist = this.state.casesData.map((cases) => {
      let id = parseInt(cases.id,0);
      while (id > 6) {
        id = id - 6
      }
      return (
        <div className={style[id]} key={cases.id}>
          <div className="layui-inline case-img"><a href={cases.path}><img src={cases.imagePath} alt="" /></a></div>
          <p className="lable"><a href={cases.path}>{cases.title}</a></p>
          <p className="cases-content">&emsp;&emsp;{cases.content}</p>
          <p align="right">
            <Link to={`/casesSingle/${cases.id}`}>詳細を表示 ></Link>
          </p>
        </div>
      )
    })

    return (
      <div className="main-case">
        <div className="layui-container">
          <div className="layui-row">
            {caselist}
          </div>
        </div>
      </div>
    )
  }
}

export default CasesMain