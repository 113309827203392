import React, { Component } from 'react'
import MyApi from '../../api/MyApi'

class HistoryMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sta: '1',
      status: false,
      message: "",
      historyData: [],
      synopsisData: [],
      // memberData: [],
    }
  }

  initData() {
    {
      // let res = {"message":"请求数据成功","c_history":[{"imagePath":"/res/static/img/us_img1.png","id":"1","time":"2017年07月","content":"SUN FLOWER SOFT株式会社成立"},{"imagePath":"/res/static/img/us_img2.png","id":"2","time":"2017年12月","content":"SUN FLOWER SOFT株式会社の箱根社内旅行を開催しました。"},{"imagePath":"/res/static/img/us_img3.png","id":"3","time":"2017年12月","content":"2017年度の年末調整完了"},{"imagePath":"/res/static/img/us_img4.png","id":"4","time":"2018年08月","content":"第一期决算完了売上5000万達成"}],"status":true}
      let res = {"message":"请求数据成功","c_history":[{"imagePath":"/res/static/img/us_img1.png","id":"1","time":"2017年07月","content":"SUN FLOWER SOFT株式会社設立"},{"imagePath":"/res/static/img/us_img2.png","id":"2","time":"2018年11月","content":"無錫碩磊科技有限公司設立"},{"imagePath":"/res/static/img/us_img5.png","id":"3","time":"2021年02月","content":"会社事務所の移転（神田へ）"}],"status":true}
      res.historyData = res.c_history
      delete res.c_history
      this.setState(res)
    }
    {
      let res = {"c_synopsis":[{"id":"1","title":"会社名","content":"SUN FLOWER SOFT株式会社"},{"id":"2","title":"設立日","content":"2017年7月31日"},{"id":"3","title":"代表取締役","content":"陳　磊"},{"id":"4","title":"取締役","content":""},{"id":"5","title":"従業員数","content":"40名(無錫側30名)"},{"id":"6","title":"資本金","content":"2000万"},{"id":"7","title":"取引銀行","content":"三菱UFJ銀行"},{"id":"8","title":"所在地","content":"〒１０１‐００４１ 東京都千代田区神田須田町１－２８－４　日宝神田ビル10階"},{"id":"9","title":"E-MAIL","content":"info@sunflower-soft.com"},{"id":"10","title":"TEL","content":"03-3525-8872"},{"id":"11","title":"FAX","content":"03-3525-8713"},{"id":"12","title":"URL","content":"www.sunflower-soft.com"}],"message":"请求数据成功","status":true}
      res.synopsisData = res.c_synopsis
      delete res.c_synopsis
      this.setState(res)
    }
    // {
    //   let res = {"message":"请求数据成功","status":true,"c_member":[{"id":"1","imagePath":"/res/static/img/member_1.jpg"},{"id":"2","imagePath":"/res/static/img/member_2.jpg"},{"id":"3","imagePath":"/res/static/img/member_3.jpg"},{"id":"4","imagePath":"/res/static/img/member_4.jpg"},{"id":"5","imagePath":"/res/static/img/member_5.jpg"},{"id":"6","imagePath":"/res/static/img/member_6.jpg"}]}
    //   res.memberData = res.c_member
    //   delete res.c_member
    //   this.setState(res)
    // }
    // MyApi.queryAllApi('post', 'queryAllApi', 'c_history').then((res) => {
    //   res.historyData = res.c_history
    //   delete res.c_history
    //   this.setState(res)
    // })
    // MyApi.queryAllApi('post', 'queryAllApi', 'c_synopsis').then((res) => {
    //   res.synopsisData = res.c_synopsis
    //   delete res.c_synopsis
    //   this.setState(res)
    // })
  }

  componentDidMount() {
    this.initData()
  }
  render() {

    //沿 革
    let historyList = this.state.historyData.map((history) => {
      let id = parseInt(history.id, 0)
      if (id % 2 !== 0) {
        return (
          <li className="odd" key={history.id}>
            <div className="cour-img"><img src={history.imagePath} alt="" /></div>
            <div className="cour-panel layui-col-sm4 layui-col-lg5">
              <p className="label">{history.time}</p>
              <p>{history.content}</p>
            </div>
          </li>
        )
      } else {
        return (
          <li key={history.id}>
            <div className="cour-img"><img src={history.imagePath} alt="" /></div>
            <div className="cour-panel layui-col-sm4 layui-col-sm-offset8 layui-col-lg5 layui-col-lg-offset7">
              <p className="label">{history.time}</p>
              <p>{history.content}</p>
            </div>
          </li>
        )
      }
    })
    //会社概要
    let synopsisList = this.state.synopsisData.map((synopsis) => {
      return (
        <tr key={synopsis.id}>
          <td>{synopsis.title}</td>
          <td>{synopsis.content}</td>
        </tr>
      )
    })
    //社員風采
    // let memberList = this.state.memberData.map((member) => {
    //   return (
    //     // <div className="layui-col-xs6 layui-col-md6">
    //       <img src={member.imagePath} className="" style={{backgroundColor: 'black',width: 300,height: 420,border: '6px solid white',boxShadow: '1px 1px 5px #333333',margin: 10}} alt="" key={member.id}></img>
    //     // </div>
    //   )
    // })

    let page

    if (this.state.sta === '1') {
      page = <div className="layui-row">
        <ul className="aboutab">
          <li className="layui-this">会社概要</li><li onClick={() => this.setState({ sta: '2' })}>&nbsp;&nbsp;沿&nbsp;&nbsp;&nbsp;革&nbsp;&nbsp;</li>{/*<li onClick={() => this.setState({ sta: '3' })}>社員風采</li>*/}
        </ul>

        {/* 会社概要 */}
        <table className="layui-table" lay-even="true" lay-size="lg">
          <colgroup>
            <col width="200" />
            <col />
          </colgroup>
          <tbody>
            {synopsisList}
          </tbody>
        </table>
      </div>
    }

    if (this.state.sta === '2') {
      page = <div className="layui-row">
        <ul className="aboutab">
          <li onClick={() => this.setState({ sta: '1' })}>会社概要</li><li className="layui-this">&nbsp;&nbsp;沿&nbsp;&nbsp;&nbsp;革&nbsp;&nbsp;</li>{/*<li onClick={() => this.setState({ sta: '3' })}>社員風采</li>*/}
        </ul>

        {/* 沿 革 */}
        <div className="tabCour">
          <ul className="timeline">
            {historyList}
            <li className="odd">
              <div className="cour-img"><img src="/res/static/img/us_img0.png" alt="" /></div>
            </li>
          </ul>
        </div>
      </div>
    }

    // if (this.state.sta === '3') {
    //   page = <div className="layui-row">
    //     <ul className="aboutab">
    //       <li onClick={() => this.setState({ sta: '1' })}>会社概要</li><li onClick={() => this.setState({ sta: '2' })}>&nbsp;&nbsp;沿&nbsp;&nbsp;&nbsp;革&nbsp;&nbsp;</li><li className="layui-this">社員風采</li>
    //     </ul>

    //     {/* 社員風采 */}
    //     <div style={{textAlign: 'center'}}>
    //       {memberList}
    //     </div>
    //   </div>
    // }

    return (
      <div className="main-about" >
        <div className="layui-container">
          {page}
        </div>
      </div>
    )
  }
}

export default HistoryMain