import React, { Component } from 'react';

class HistoryBanner extends Component {

  render() {

    return (
      <div className="banner product layui-container">
        <div className="title">
          <p>会社概要</p>
          <p className="en">Company summary</p>
        </div>
      </div>
    )
  }
}

export default HistoryBanner
