import React, { Component } from 'react'
// import Link from 'react-router-dom'
// import connect from 'react-redux'
import Nav from '../../../components/nav/Nav'
import Footer from '../../../components/footer/Footer'
import CasesSingleMain from '../../../components/main/CasesSingleMain';

class CasesSingle extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <CasesSingleMain></CasesSingleMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default CasesSingle