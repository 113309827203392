import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import MyApi from '../../api/MyApi'

class IndexMain extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: false,
      message: "",
      picData: [],
      indexData: [],
      serviceData: [],
      introduceData: []
    }
  }
  initData() {
    {
      let res = {"message":"请求数据成功","c_business":[{"imagePath":"/res/static/img/Big_icon1.png","id":"1","title":"金融","content":"私達は金融業界と全体の応用環境に対し、比較的深い理解が有り、システム自身の信頼性、安定性、安全性などの方面に高く要求し、取引先の需要を満たします。"},{"imagePath":"/res/static/img/Big_icon2.png","id":"2","title":"保険","content":"保険業界ではデジタルなど新たなテーマにより、新たな課題、またビジネスチャンスが生まれています。我々がお客様の挑戦に効果的な対処するため、大量のデータを整理し、業務の方策を支援します。"},{"imagePath":"/res/static/img/Big_icon3.png","id":"3","title":"販売","content":"全体解決のコンサルティングサービス、全ライフサイクルのITサービスなどを含み、お客様のデジタル化の転換を加速させます。"},{"imagePath":"/res/static/img/Big_icon4.png","id":"4","title":"医療·福祉","content":"深い技術経験と業界に対する理解により、お客様の業務ニーズに立脚し、全方位のシステム開発とメンテナンスを提供することができます。"}],"status":true}
      res.indexData = res.c_business
      delete res.c_business
      this.setState(res)
    }
    {
      let res = {"c_service":[{"imagePath":"/res/static/img/home_img1.jpg","id":"1","title":"SES（常駐型技術支援サービス）","content":"• SUN FLOWER SOFTが提供しているSES（常駐型技術支援サービス）は、主に大手SIER（システムインテグレーター）を対象顧客にしております。"},{"imagePath":"/res/static/img/home_img2.jpg","id":"2","title":"システム受託開発サービス","content":"• SUN FLOWER SOFTはこれまで携わった様々な業界の開発経験をベースに、お客様が必要とする情報システムをオーダーメイドで構築できます。"}],"message":"请求数据成功","status":true}
      res.serviceData = res.c_service
      delete res.c_service
      this.setState(res)
    }
    {
      let res = {"c_introduce":[{"imagePath":"/res/static/img/us_img1.jpg","id":"1","content":"IT技術はどんどんと発展し、私達の生活を便利に、豊かにしていきます。社会と共に進化し、IT企業として弊社はお客様と技術者のパーフェクトマッチングを追求致します。"},{"imagePath":"/res/static/img/us_img2.jpg","id":"2","content":"優秀な人材と豊かなお客様資源は企業発展の根本であり、社員一人一人の能力向上と成長を図り、活力に満ちた信頼される企業を目指します。弊社は大量の人材を投入し、グローバル企業で活躍できる人材を育成、お客様に提供し続ける企業として尽力して参ります。"},{"imagePath":"/res/static/img/us_img3.jpg","id":"3","content":"今後とも、ご支援を賜りますようよろしくお願い申し上げます。"}],"message":"请求数据成功","status":true}
      res.introduceData = res.c_introduce
      delete res.c_service
      this.setState(res)
    }

    // MyApi.queryAllApi('post', 'queryAllApi', 'c_business').then((res) => {
    //   res.indexData = res.c_business
    //   delete res.c_business
    //   this.setState(res)
    // })

    // MyApi.queryAllApi('post', 'queryAllApi', 'c_service').then((res) => {
    //   res.serviceData = res.c_service
    //   delete res.c_service
    //   this.setState(res)
    // })

    // MyApi.queryAllApi('post', 'queryAllApi', 'c_introduce').then((res) => {
    //   res.introduceData = res.c_introduce
    //   delete res.c_service
    //   this.setState(res)
    // })
  }

  componentDidMount() {
    this.initData()
  }

  render() {

    // 核心产品
    let indexList = this.state.indexData.map((index) => {
      return (
        <div className="layui-col-sm6 layui-col-md3" key={index.id}>
          <div className="content">
            <div><img src={index.imagePath} alt="" /></div>
            <div>
              <p className="label">{index.title}</p>
              <p className="pcontent">&emsp;&emsp;{index.content}</p>
            </div>
            <Link to="#">More +</Link>
          </div>
        </div>
      )
    })

    // 服务
    let serviceList = this.state.serviceData.map((serviceData) => {
      return (
        <div className="layui-col-sm6" key={serviceData.id}>
          <div className="content">
            <div className="content-left"><img src={serviceData.imagePath} alt="" /></div>
            <div className="content-right">
              <p className="label">{serviceData.title}</p>
              <span></span>
              <p>{serviceData.content}</p>
            </div>
          </div>
        </div>
      )
    })

    //总经理寄语
    let introduceList = this.state.introduceData.map((introduce) => {
      return (
        <p key={introduce.id}>&emsp;&emsp;{introduce.content}</p>
      )
    })

    return (
      <div>
        <div className="main-product">
          <div className="layui-container">
            <p className="title"><span>業務分野</span></p>
            <div className="layui-row layui-col-space25">

              {/* 核心产品 */}
              {indexList}

            </div>
          </div>
        </div>
        <div className="main-service">
          <div className="layui-container">
            <p className="title">取引先のために完璧な<span>専門サービス</span>を製造します</p>
            <div className="layui-row layui-col-space25 layui-col-space80">

              {/* 产品服务 */}
              {serviceList}

            </div>
            {/* <div className="service-more"></div> */}
          </div>
        </div>
        <div className="main-service">
          <div className="layui-container">
            <div className="layui-col-xs12 layui-col-sm6 layui-col-md6 shouyejiyu">
              <p className="title jiyu"><i className="layui-icon">&#xe60c;</i>&nbsp;<span>社長メッセージ</span></p>
              <div className="layui-card">
                {/* <div className="layui-card-header">卡片面板</div> */}
                <div className="layui-card-body">
                  {introduceList}
                </div>
              </div>
            </div>
            <div className="layui-col-xs12 layui-col-sm6 layui-col-md6 shouyeditu">
              <p className="title"><span>アクセス</span></p>
              <iframe title="1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.2742868522237!2d139.76800071561237!3d35.694867336830754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c0302490b8b%3A0x9318b716a6f61ac0!2z5pel5pys44CSMTAxLTAwNDEgVG9reW8sIENoaXlvZGEgQ2l0eSwgS2FuZGEgU3VkYWNoxY0sIDEtY2jFjW1l4oiS77yS77yY4oiSNA!5e0!3m2!1szh-CN!2shk!4v1612768445613!5m2!1szh-CN!2shk"></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IndexMain