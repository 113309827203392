import React, { Component } from 'react';
import MyApi from '../../api/MyApi'

class IndexBanner extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: false,
      message: "",
      imgData: []
    }
  }

  initData() {
    
    let res = {"c_banner":[{"imagePath":"/res/static/img/nav_img0.jpg","id":"1"},{"imagePath":"/res/static/img/nav_img1.jpg","id":"2"},{"imagePath":"/res/static/img/nav_img2.jpg","id":"3"}],"message":"请求数据成功","status":true}
    res.imgData = res.c_banner
    delete res.c_banner
    this.setState(res)

    // MyApi.queryAllApi('post', 'queryAllApi', 'c_banner').then((res) => {
    //   res.imgData = res.c_banner
    //   delete res.c_banner
    //   this.setState(res)
    // })
  }

  componentDidMount() {
    this.initData();
    let babel1 = document.getElementById("label1");
    let babel2 = document.getElementById("label2");
    let babel3 = document.getElementById("label3");
    let arr = [babel1, babel2, babel3];
    let temp = 0;
    setInterval(() => {
      arr[temp].click();
      temp++;
      if (temp > 2) temp = 0;
    }, 3000);
  }

  render() {
    let imgList = this.state.imgData.map((img) => {
      return (
        <li className={'num' + img.id} key={img.id}><img src={img.imagePath} alt=""/></li>
      )
    })
    return (
      <div className="bannerindex layui-container">
        <input name="ban" id="ban1" type="radio" />
        <input name="ban" id="ban2" type="radio" />
        <input name="ban" id="ban3" type="radio" />
        <ul>
          {imgList}
        </ul>
        <div className="nev">
          <label className="num1" htmlFor="ban1" id="label1"></label>
          <label className="num2" htmlFor="ban2" id="label2"></label>
          <label className="num3" htmlFor="ban3" id="label3"></label>
        </div>
        <div className="nxt">
          <label className="num1" htmlFor="ban1"></label>
          <label className="num2" htmlFor="ban2"></label>
          <label className="num3" htmlFor="ban3"></label>
        </div>
        <div className="nts">
          <label className="num1" htmlFor="ban1"></label>
          <label className="num2" htmlFor="ban2"></label>
          <label className="num3" htmlFor="ban3"></label>
        </div>
      </div>
    )
  }
}

export default IndexBanner
