import axios from 'axios';
import envconfig from '../envconfig/envconfig';

export default class Server {
  axios(method, url, tableName) {
    if (method === 'get') {
      return new Promise((resolve, reject) => {
        axios.get(envconfig.javaURL + url, {"tableName": tableName}).then(res => {
          resolve(typeof res.data === 'object' ? res.data : JSON.parse(res.data))
        }, error => {
          if (error.response) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
      })
    }
    if (method === 'post') {
      return new Promise((resolve, reject) => {
        axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
        axios.post(envconfig.javaURL + url, {"tableName": tableName}).then(res => {
          resolve(typeof res.data === 'object' ? res.data : JSON.parse(res.data))
        }, error => {
          if (error.response) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
      })
    } else {
      console.error('请输入get或post请求方式')
    }

  }
  axiosById(method, url, tableName, id){
    if (method === 'get') {
      return new Promise((resolve, reject) => {
        axios.get(envconfig.javaURL + url, {"tableName": tableName,"id": id}).then(res => {
          resolve(typeof res.data === 'object' ? res.data : JSON.parse(res.data))
        }, error => {
          if (error.response) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
      })
    }
    if (method === 'post') {
      return new Promise((resolve, reject) => {
        axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
        axios.post(envconfig.javaURL + url, {"tableName": tableName,"id": id}).then(res => {
          resolve(typeof res.data === 'object' ? res.data : JSON.parse(res.data))
        }, error => {
          if (error.response) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
      })
    } else {
      console.error('请输入get或post请求方式')
    }

  }
}
