import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import Footer from '../../components/footer/Footer';
import Nav from '../../components/nav/Nav';
import IndexBanner from '../../components/banner/IndexBanner'
import IndexMain from '../../components/main/IndexMain'

class Index extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <IndexBanner></IndexBanner>
        <IndexMain></IndexMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default Index
